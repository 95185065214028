import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const Error = () => {
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "404",
        }}
      />
      <main className="container">
        <section className="mt-10 flex flex-col items-center justify-center space-y-5 text-center">
          <h1>404</h1>
          <div>
            <h3 className="py-5">
              ¡Ups! No encontramos la página que buscas 👀
            </h3>
            <Link
              to="/"
              className="rounded bg-exoticberry py-2 px-4 text-white"
            >
              Regresa al Inicio
            </Link>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Error;
